'use strict';

import * as L from 'leaflet';
import * as turf from '@turf/turf';

import { round, stringifyNumber } from '../utilities/formatNumber';
import { MapService } from '../services/map.service';

export class PlotFactory {
    protected distributionMethodsInfo = [
        {
            id: 'linéaire',
            libelle: 'Linéaire',
        },
        {
            id: 'quantile',
            libelle: 'Quantile',
        },
        {
            id: 'moyenne',
            libelle: 'Moyennes emboitées',
        },
        {
            id: 'standardDeviation',
            libelle: 'Ecarts types',
        },
        {
            id: 'naturalBreaks',
            libelle: 'Seuils naturels',
        },
    ];
    protected indicatorId: number;
    protected form = ' ';
    protected classCount = 0;
    protected degradeId = 34;
    protected degrade = 'GrnYelRed';
    protected distributionMethodInfo = null;
    protected indicatorfillopacity = 0.8;
    protected indicatorcontouropacity = 1;
    protected indicatorcontourcolor = 'black';
    protected indicatorcontourweight = 1;
    protected default_radius_weight = 1;
    protected exclude_null = 0;
    protected tableCouleurs = null;
    protected valueScale = null;
    protected dataToPlot = {};
    protected geojson = null;
    public geojsonLayer = null;
    protected type = 'valeur';
    // protected CursorWeightConstant = 1;
    // protected CursorContourWeightConstant = 1;
    protected unavailableColor = '#C0C0C0';
    protected couleur_zero_in_lgd = '#d3d3d3';
    protected zero_if_null_dyn = 0;
    protected info_nom: string;
    protected libelle_indic_short: string;
    protected shortLabel: string;
    protected label: string;
    protected text_null: string;
    protected unit: string;
    protected unit_short: string;
    protected legende: any[];
    protected class_label: any[];
    protected separate_zero_in_lgd: number;
    protected decimalCount: number;
    protected chart_divider: any;
    protected legendBoundaries: number[][];
    protected distributionService: any;
    protected vector_base: string;

    public openPopup: boolean = true;
    public popupMaxHeight = 200;
    public popupMinWidth = 100;
    public popupMaxWidth = 600;

    constructor(indicatorId: number, protected mapService: MapService) {
        this.indicatorId = indicatorId;
        this.mapService = mapService;
    }

    updateLayer() {
        const newGeojson = L.geoJson(this.geojson, {
            // I comment this line for now, bc it breaks the possibility to highlight different layers
            // Need to investigate that a bit more
            //renderer: renderer,
            style: this.getStyle.bind(this),
            onEachFeature: this.onEachFeature.bind(this),
        });

        // remove old layers which are no longer in map bounds
        // or features with null value because it might get wrongly assigned if user moved the map again and gain and again....
        const map = this.mapService.map;
        const mapBounds = map.getBounds();
        const toRemove = new L.FeatureGroup();

        this.geojsonLayer.eachLayer((layer: any) => {
            const isSelected = layer.feature.properties.isSelected;
            const isNull = layer.feature.properties.value == null;
            if (!isSelected || isNull) {
                const layerBounds = layer.getBounds();
                if (!mapBounds.intersects(layerBounds)) {
                    toRemove.addLayer(layer);
                }
            }
        });

        // well it is possible to request the same area simultaneously (by zooming in and zooming out for example),
        // so we better check if feature is not already there before adding it
        const toAdd = new L.FeatureGroup();
        newGeojson.eachLayer((newlayer: any) => {
            const newId = newlayer.feature.properties.id_ter || newlayer.feature.properties.gridId;
            const layer: any = this.findLayerBySiterreId(newId);
            const isNull = layer ? layer.feature.properties.value == null : false;
            if (!layer || isNull) {
                toAdd.addLayer(newlayer);
            }
        });

        toRemove.eachLayer((layer: L.Layer) => this.geojsonLayer.removeLayer(layer));
        toAdd.eachLayer((layer: L.Layer) => this.geojsonLayer.addLayer(layer));

        this.geojson = this.geojsonLayer.toGeoJSON();
        // this.mapService.removeLayers(toRemove.getLayers());
        // this.mapService.addLayers(newGeojson.getLayers());
    }

    refreshLayer(map: L.Map = this.mapService.map) {
        if (map.hasLayer(this.geojsonLayer)) {
            this.removeLayer(map);
            this.addLayer(map);
        }
    }

    removeLayer(map: L.Map = this.mapService.map) {
        map.removeLayer(this.geojsonLayer);
        this.geojsonLayer.clearLayers();
        // this.mapService.removeLayers(this.geojsonLayer.getLayers());
    }

    addLayer(map: L.Map = this.mapService.map, rendererEngine: string = 'canvas') {
        if (this.geojsonLayer != null) {
            this.removeLayer(map);
        }

        // const renderer = rendererEngine == 'svg' ? L.svg() : L.canvas();
        this.geojsonLayer = L.geoJson(this.geojson, {
            // I comment this line for now, bc it breaks the possibility to highlight different layers
            // Need to investigate that a bit more
            //renderer: renderer,
            style: this.getStyle.bind(this),
            onEachFeature: this.onEachFeature.bind(this),
        });
        map.addLayer(this.geojsonLayer);

        // this.mapService.addLayers(this.geojsonLayer.getLayers());
    }

    onEachFeature(feature: GeoJSON.Feature, layer: L.Layer) {
        feature.properties.id_indicateur = this.indicatorId;

        layer.on({
            mouseover: this.handleMouseOver.bind(this),
            mouseout: this.handleMouseOut.bind(this),
            click: this.handleClick.bind(this),
        });
    }

    handleMouseOver(e: any) {
        const layer = e.target;
        const feature = layer.feature;
        const isPopupClickOn = feature.properties.isPopupClickOn;
        const isSelected = feature.properties.isSelected;

        if (!isPopupClickOn) {
            if (!isSelected) {
                this.highlightFeature(layer);
            }

            this.setTooltip(layer);
        }
    }

    setTooltip(layer: any) {
        const feature = layer.feature;

        const tooltipContent = this.setTooltipContent(feature);
        layer.bindTooltip(tooltipContent, {
            direction: 'top',
        });

        // here is the crazy part: leaflet does not initialized the tooltip correctly
        // (this first time it appears, the tooltip is placed at the wrong position)
        // so we have to initialize it manually
        const latLng = this.getOverlayPosition(layer);
        const tooltip = layer.getTooltip();
        tooltip._latlng = latLng;
        tooltip._map = this.mapService.map;
        tooltip._initLayout();

        layer.openTooltip();
    }

    highlightFeature(layer: L.Layer) {
        //Interface
    }

    handleMouseOut(e: any) {
        const layer = e.target;
        const feature = layer.feature;
        const isSelected = feature.properties.isSelected;

        layer.closeTooltip().unbindTooltip();

        if (!isSelected) {
            if (layer.setStyle) {
                const style = this.getStyle(feature);
                layer.setStyle(style);
            }
            this.unhighlightFeature(layer);
        }
    }

    unhighlightFeature(layer: L.Layer) {
        //Interface
    }

    handleClick(e: any) {
        L.DomEvent.stopPropagation(e);

        const layer = e.target;
        this.setClickPopup(layer);

        this.handleClickOnFeature(e);
    }

    setClickPopup(layer: any, withBodyComplement: boolean = true) {
        const feature = layer.feature;

        const isAlreadyClickOn = feature.properties.isPopupClickOn;
        const isAlreadyPinOn = feature.properties.isPopupPinOn;

        if (!isAlreadyClickOn) {
            this.mapService.closeAllPopup();
            feature.properties.isPopupClickOn = true;
        }

        let latLng: L.LatLng;
        if (isAlreadyPinOn) {
            latLng = feature.properties.popupLatLng;
        } else {
            latLng = this.getOverlayPosition(layer);
        }

        const popupContent = this.setPopupContent(feature, withBodyComplement);

        layer.closeTooltip().unbindTooltip().closePopup().unbindPopup().bindPopup(popupContent, {
            autoClose: false,
            closeOnClick: false,
            maxHeight: this.popupMaxHeight,
            maxWidth: this.popupMaxWidth,
            minWidth: this.popupMinWidth,
        });

        if (this.openPopup) {
            layer.openPopup(latLng);
        }

        this.handlePinup(layer, latLng);
        this.handleClosePopup(layer);

        this.handlePopupCustomEvent(layer);
    }

    handlePinup(layer: any, latLng: L.LatLng) {
        const feature = layer.feature;

        const popupElement = layer.getPopup();
        const wrapper = popupElement.getElement();
        const pinButton = $(wrapper).find('.leaflet-popup-pin-button');

        pinButton.on('click', () => {
            const isPopupPinOn = feature.properties.isPopupPinOn;

            if (isPopupPinOn) {
                pinButton.removeClass('active');
                pinButton.attr('title', 'Epingler');
                feature.properties.isPopupPinOn = false;
                feature.properties.popupLatLng = null;
            } else {
                pinButton.addClass('active');
                pinButton.attr('title', 'Détacher');
                feature.properties.isPopupPinOn = true;
                feature.properties.popupLatLng = latLng;
            }
        });
    }

    handleClosePopup(layer: any) {
        const feature = layer.feature;

        const popupElement: any = layer.getPopup();
        const closeButton = $(popupElement._closeButton);
        closeButton.attr('title', 'Fermer');

        closeButton.one('click', () => {
            feature.properties.isPopupClickOn = false;
            feature.properties.isPopupPinOn = false;
            feature.properties.popupLatLng = null;
        });
    }

    handleClickOnFeature(e) {
        console.log(`select ${this.form} element`);
    }

    deselectElement(id: string) {
        const layer: any = this.findLayerBySiterreId(id);
        if (layer) {
            const feature = layer.feature;
            feature.properties.isSelected = false;

            if (layer.setStyle) {
                const style = this.getStyle(feature);
                layer.setStyle(style);
            }

            layer.closePopup();

            // this.unhighlightFeature(e);
        }
    }

    targetElement(id: string, withBodyComplement: boolean = true) {
        const layer = this.findLayerBySiterreId(id);

        if (layer) {
            this.setClickPopup(layer, withBodyComplement);
            // this.mapService.map.once('click', () => layer.closeTooltip().unbindTooltip());

            // const latLng = this.getPointOnFeature(layer);
            // const leftPanelWidth = $('#left-panel').width();
            // const shiftPoint = L.point(-leftPanelWidth / 2, 0);
            // const shiftedLatLng = this.mapService.getShiftedLatLng(latLng, shiftPoint);
            // this.mapService.setView(shiftedLatLng);
        }
    }

    //========================= Méthode de sélection de la couleur à afficher par élement ==================
    getStyle(_feature: GeoJSON.Feature, _latLng?: { lat: number; lng: number }): any {
        //Interface
    }

    getLegendIndex(value: number) {
        // if ((value === undefined || value === null) && this.zero_if_null_dyn) {
        //     value = 0;
        // }

        const index = this.distributionService.getLegendBoundaryIndex(this, value);
        return index;
    }

    getColor(value: number) {
        const isClassIndicator = this.type === 'class';
        const isValueDefined = value !== null && value !== undefined;

        if (isClassIndicator && isValueDefined) {
            const legend = this.legende.find((legend) => legend.id_class === value);

            if (legend) {
                legend.exist = true;
                return legend.color;
            }
            return this.unavailableColor;
        }

        if (!isValueDefined && this.zero_if_null_dyn) {
            value = 0;
        }

        if (value === 0 && this.separate_zero_in_lgd === 1) {
            return this.couleur_zero_in_lgd;
        }
        if (typeof value === 'number' && !isNaN(value)) {
            const index = this.getLegendIndex(value);
            const color = this.tableCouleurs[index];
            return color;
        }

        return this.unavailableColor;
    }

    getFillOpacity(d) {
        if (this.type === 'class' && this.class_label) {
            const labelInfo = this.class_label.find((labelInfo) => labelInfo.id_class === d) || {};
            return (labelInfo.default_fillopacity || 1) * this.indicatorfillopacity;
        }

        return this.indicatorfillopacity;
    }

    getContourOpacity(d) {
        if (this.type === 'class' && this.class_label) {
            const labelInfo = this.class_label.find((labelInfo) => labelInfo.id_class === d) || {};
            return (labelInfo.default_contouropacity || 1) * this.indicatorcontouropacity;
        }

        return this.indicatorcontouropacity;
    }

    getWeight(d) {
        this.default_radius_weight = this.default_radius_weight ? this.default_radius_weight : 1;

        if (this.type === 'class' && this.class_label) {
            const labelInfo = this.class_label.find((labelInfo) => labelInfo.id_class === d) || {};
            return (labelInfo.default_radius_weight || 1) * this.default_radius_weight;
        }

        return this.default_radius_weight;
    }

    //==========Fonction de génération du popup au survol de l'élement =========================//
    setTooltipContent(feature: GeoJSON.Feature) {
        const header = this.setHeaderPopupContent(feature);
        const body = this.setBodyPopupContent(feature);

        const hoverPopupContent = `
            <div>
                ${header}
                ${body}
            </div>
        `;
        return hoverPopupContent;
    }

    setPopupContent(feature: GeoJSON.Feature, withBodyComplement: boolean = true) {
        const isPopupPinOn = feature.properties.isPopupPinOn;
        const title = isPopupPinOn ? 'Détacher' : 'Epingler';
        const active = isPopupPinOn ? 'active' : '';

        let header: string;
        let body: string;
        let bodyComplement: string = '';

        header = this.setHeaderPopupContent(feature);
        body = this.setBodyPopupContent(feature);

        if (withBodyComplement) {
            bodyComplement = this.setBodyComplementPopupContent(feature);
        }

        const clickPopupContent = `
            <a class="leaflet-popup-pin-button ${active}" title="${title}">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pin" viewBox="0 0 16 16">
                    <path d="M4.146.146A.5.5 0 0 1 4.5 0h7a.5.5 0 0 1 .5.5c0 .68-.342 1.174-.646 1.479-.126.125-.25.224-.354.298v4.431l.078.048c.203.127.476.314.751.555C12.36 7.775 13 8.527 13 9.5a.5.5 0 0 1-.5.5h-4v4.5c0 .276-.224 1.5-.5 1.5s-.5-1.224-.5-1.5V10h-4a.5.5 0 0 1-.5-.5c0-.973.64-1.725 1.17-2.189A6 6 0 0 1 5 6.708V2.277a3 3 0 0 1-.354-.298C4.342 1.674 4 1.179 4 .5a.5.5 0 0 1 .146-.354m1.58 1.408-.002-.001zm-.002-.001.002.001A.5.5 0 0 1 6 2v5a.5.5 0 0 1-.276.447h-.002l-.012.007-.054.03a5 5 0 0 0-.827.58c-.318.278-.585.596-.725.936h7.792c-.14-.34-.407-.658-.725-.936a5 5 0 0 0-.881-.61l-.012-.006h-.002A.5.5 0 0 1 10 7V2a.5.5 0 0 1 .295-.458 1.8 1.8 0 0 0 .351-.271c.08-.08.155-.17.214-.271H5.14q.091.15.214.271a1.8 1.8 0 0 0 .37.282"/>
                </svg>
            </a>
            <div class="popup-content"
                <div>
                    ${header}
                    ${body}
                    ${bodyComplement}
                </div>
            </div>
        `;
        return clickPopupContent;
    }

    handlePopupCustomEvent(layer: any) {
        //Interface
    }

    setHeaderPopupContent(feature: GeoJSON.Feature) {
        let header = '';
        if (feature.properties.label) {
            header = feature.properties.label;
        }

        if (feature.properties.lib_ter) {
            header = feature.properties.lib_ter;
        }

        if (feature.properties.titre) {
            header = feature.properties.titre;
        }

        if (!header) {
            return '';
        }

        header = `
            <div class="header">
                ${header}
            </div>
        `;
        return header;
    }

    setBodyPopupContent(feature: GeoJSON.Feature) {
        let body = '';

        const label = this.label;
        const unit = this.unit || '';

        if ([null, undefined].includes(feature.properties.value)) {
            body = `
                <div>
                    <b>${label}</b> : ${this.text_null}
                </div>
            `;
        } else if (this.type === 'class') {
            let labelInfo: any;
            if (feature.properties.originalValue) {
                labelInfo = this.class_label.find(
                    (labelInfo) => labelInfo.id_class == feature.properties.originalValue,
                );
            } else {
                labelInfo = this.class_label.find(
                    (labelInfo) => labelInfo.id_class == feature.properties.value,
                );
            }
            if (labelInfo) {
                const value = labelInfo.lib_class || 'non disponible';
                body = `
                    <div>
                        <b>${label}</b> : ${value}
                    </div>
                `;
            }
        } else if (this.type === 'valeur') {
            const value = round(feature.properties.value, this.decimalCount);
            const valueStringified = stringifyNumber(value, this.decimalCount);
            body = `
                <div>
                    <b>${label}</b> : ${valueStringified} ${unit}
                </div>
            `;
        }

        if (this.form === 'chart') {
            const chartDivider = JSON.parse(this.chart_divider);

            Object.keys(chartDivider).forEach((key, index) => {
                const label = chartDivider[key];
                const value = feature.properties[`value_${index + 1}`]
                    ? feature.properties[`value_${index + 1}`]
                    : '0';
                const valueStringified = stringifyNumber(value, this.decimalCount);

                body += `
                    <div>
                        <b>${label}</b> : ${valueStringified} ${unit}
                    </div>
                `;
            });
        }
        return body;
    }

    setBodyComplementPopupContent(feature: GeoJSON.Feature) {
        let bodyComplement = '';

        const featureLabel = this.libelle_indic_short;

        const popUpInfoStringified = this.info_nom;
        if (popUpInfoStringified) {
            const popUpInfo = JSON.parse(popUpInfoStringified);

            for (const key in popUpInfo) {
                const label = popUpInfo[key];

                if (!label.includes(featureLabel)) {
                    const value = feature.properties[key];

                    if (typeof value != 'undefined') {
                        const formatedValue = this.formatValue(label, value);

                        bodyComplement += `
                            <div class="body-complement">
                                ${label} : <span class="light">${formatedValue}</span>
                            </div>
                        `;
                    }
                }
            }
        }

        const popUpInfo = feature.properties.miscInfo;
        if (popUpInfo) {
            for (const [label, value] of Object.entries(popUpInfo)) {
                if (!label.includes(featureLabel)) {
                    const formatedValue = this.formatValue(label, value);

                    bodyComplement += `
                        <div class="body-complement">
                            ${label} : <span class="light">${formatedValue}</span>
                        </div>
                    `;
                }
            }
        }

        if (!bodyComplement) {
            return '';
        }

        bodyComplement = `
            <div class="px-1 pt-2">
                ${bodyComplement}
            </div>
        `;
        return bodyComplement;
    }

    formatValue(label: string, value: any) {
        if ([null, undefined].includes(value)) {
            value = 'NR';
        }

        if (typeof value == 'boolean') {
            value = value ? 'Oui' : 'Non';
        }

        if (!isNaN(value) && !label.includes('Année')) {
            value = stringifyNumber(value);
        }
        return value;
    }

    //------------------------------------------------------------------------------------------------------------------
    findLayerBySiterreId(id: string, featureGroup: L.FeatureGroup = this.geojsonLayer) {
        const layers = featureGroup.getLayers();
        const layer = layers.find(
            (layer: any) =>
                layer.feature.properties.id_ter == id || layer.feature.properties.id == id,
        );
        return layer;
    }

    getOverlayPosition(layer: any) {
        if (layer instanceof L.Polyline) {
            return this.getNorthernmostLatLng(layer);
        }
        return layer.getLatLng();
    }

    getNorthernmostLatLng(layer: any) {
        const latLngs = layer.getLatLngs().flat(Infinity);

        // const initLatLng = this.getPointOnFeature(latLngs[0]);
        const initPoint = this.mapService.convertLatLngToContainerPoint(latLngs[0]);

        const northernmostPoint = latLngs
            .slice(1)
            .reduce((northernmostPoint: L.Point, latLng: L.LatLng) => {
                const containerPoint = this.mapService.convertLatLngToContainerPoint(latLng);
                return containerPoint.y < northernmostPoint.y ? containerPoint : northernmostPoint;
            }, initPoint);

        const northernmostLatLng = this.mapService.convertContainerPointToLatLng(northernmostPoint);
        return northernmostLatLng;
    }

    getPointOnFeature(layer: any) {
        const feature = layer.feature;
        const pointOnPolygon = turf.pointOnFeature(feature);
        const coordinates = pointOnPolygon.geometry.coordinates;
        return L.latLng(coordinates[1], coordinates[0]);
    }

    // fonction de récupération du point le plus au nord d'un polygone
    getPopupPositionX(feature) {
        let latitude = 0;
        let longitude = 0;

        feature.geometry.coordinates[0][0].forEach((point) => {
            if (point[1] > latitude) {
                longitude = point[0];
                latitude = point[1];
            }
        });
        return [latitude, longitude];
    }
}
